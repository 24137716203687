
var cc = initCookieConsent();


cc.run({
    current_lang: 'cs',
    autoclear_cookies: true,                   // default: false
    theme_css: 'https://cdn.jsdelivr.net/gh/orestbida/cookieconsent@v2.7.1/dist/cookieconsent.css',  // 🚨 replace with a valid path
    page_scripts: true,                        // default: false

    // delay: 0,                               // default: 0
    auto_language: 'document',               // default: null; could also be 'browser' or 'document'
    // autorun: true,                          // default: true
    // force_consent: false,                   // default: false
    // hide_from_bots: false,                  // default: false
    // remove_cookie_tables: false             // default: false
    // cookie_name: 'cc_cookie',               // default: 'cc_cookie'
    // cookie_expiration: 182,                 // default: 182 (days)
    // cookie_necessary_only_expiration: 182   // default: disabled
    // cookie_domain: location.hostname,       // default: current domain
    // cookie_path: '/',                       // default: root
    // cookie_same_site: 'Lax',                // default: 'Lax'
    // use_rfc_cookie: false,                  // default: false
    // revision: 0,                            // default: 0

    onFirstAction: function (user_preferences, cookie) {
        // callback triggered only once
    },

    onAccept: function (cookie) {

    },

    onChange: function (cookie, changed_preferences) {

    },

    languages: {
        'cs': {
            consent_modal: {
                title: 'Používáme cookies!',
                description: 'K provozování těchto webových stránek společnost Českomoravská Nemovitostní a.s. používá pouze soubory cookies a podobné technologie v nezbytném rozsahu. Volitelné soubory cookies za účelem zlepšení a personalizace shromažďování analytických údajů, jako je počet návštěv a zdrojů návštěvnosti, poskytování reklamy a komunikace s třetími stranami společnost Českomoravská Nemovitostní a.s. nepoužívá. <button type="button" class="cc-link no-hover"><a href="documents/cmn-informace-o-cookies.pdf" target="_blank">Více informací zde</a></button>',
                primary_btn: {
                    text: 'Rozumím',
                    role: 'accept_all'              // 'accept_selected' or 'accept_all'
                }
            },
            settings_modal: {
                title: 'Předvolby souborů cookie',
                save_settings_btn: 'Uložit nastavení',
                accept_all_btn: 'Potvrdit vše',
                reject_all_btn: 'Odmítnout vše',
                close_btn_label: 'Zavřít',
                cookie_table_headers: [
                    {col1: 'Název'},
                    {col2: 'Doména'},
                    {col3: 'Expirace'},
                    {col4: 'Popis'}
                ],
                blocks: [
                    {
                        title: 'Používání souborů cookie',
                        description: 'Soubory cookie používám k zajištění základních funkcí webových stránek a ke zlepšení vašeho online zážitku. U každé kategorie si můžete zvolit, zda se chcete přihlásit nebo odhlásit, kdykoli budete chtít. Další podrobnosti týkající se souborů cookie a dalších citlivých údajů naleznete v úplném znění zde: <a href="/documents/cmn-informace-o-cookies.pdf" target="_blank" class="cc-link">Zásady užívaní souboru cookies</a>.'
                    }, {
                        title: 'Nezbytné',
                        description: 'Tyto cookies jsou vyžadovány pro správnou funkčnost našich webových stránek a v našem systému je nelze vypnout.',
                        toggle: {
                            value: 'necessary',
                            enabled: true,
                            readonly: true          // cookie categories with readonly=true are all treated as "necessary cookies"
                        },
                        cookie_table: [             // list of all expected cookies
                            {
                                col1: 'cc_cookie',       // match all cookies starting with "_ga"
                                col2: '.crystal.cm-n.cz',
                                col3: '6 měsíců',
                                col4: 'Uchovává informace o souhlasu s používáním cookies',
                                is_regex: true
                            }
                        ]
                    }, {
                        title: 'Výkonnostní a analytické',
                        description: 'Tyto soubory cookie umožňují webové stránce zapamatovat si volby, které jste provedli v minulosti.',
                        toggle: {
                            value: 'analytics',     // your cookie category
                            enabled: false,
                            readonly: false
                        },
                        cookie_table: [             // list of all expected cookies
                            {
                                col1: '_ga',       // match all cookies starting with "_ga"
                                col2: '.crystal.cm-n.cz',
                                col3: '2 roky',
                                col4: 'Tento název souboru cookie je spojen se službou Google Universal Analytics, což je významná aktualizace běžněji používané analytické služby společnosti Google. Tento soubor cookie se používá k rozlišení jedinečných uživatelů přiřazením náhodně vygenerovaného čísla jako identifikátoru klienta. Je obsažen v každém požadavku na stránku na webu a slouží k výpočtu údajů o návštěvnících, relacích a kampaních pro analytické přehledy webu.',
                                is_regex: true
                            },
                            {
                                col1: '_gid',
                                col2: '.crystal.cm-n.cz',
                                col3: '1 den',
                                col4: 'Tento soubor cookie je nastaven službou Google Analytics. Ukládá a aktualizuje jedinečnou hodnotu pro každou navštívenou stránku a slouží k počítání a sledování zobrazení stránek.',
                            }
                        ]
                    }
                ]
            }
        }


    }
});
